import { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { Helmet } from 'react-helmet';
import Heading from '../../components/Heading/Heading';
import Tiles from '../../components/Tile/Tiles';
import { getTestProps } from '../../lib/helpers';
import {
  CopyIcon,
  QuestionMarkWavedIcon,
  BookIcon,
  LightningIcon,
  ZoomMaleWorkingImage,
} from '../../images/shapes';
import UserContext from '../../contexts/UserContext';

const HomeStructure = ({
  testId,
  welcomeImage,
  description,
  rightSidebar,
  space,
  buildUrlWithSpace,
  children,
}) => {
  const { t } = useTranslation();
  const { userData } = useContext(UserContext);

  const DEFAULT_TILES = useMemo(
    () => [
      {
        title: t('Global.Documentation'),
        icon: <BookIcon className="h-9 text-white mb-2" />,
        link: process.env.REACT_APP_DOCUMENTATION,
        target: '_blank',
        rel: 'noreferrer',
      },
      {
        key: 'blog',
        title: t('Global.Blog'),
        icon: <CopyIcon className="h-8 text-white mb-2" />,
        link: process.env.REACT_APP_BLOG,
        target: '_blank',
        rel: 'noreferrer',
      },
      {
        key: 'discord',
        title: t('Global.Discord'),
        icon: <QuestionMarkWavedIcon className="h-9 text-white mb-2" />,
        link: process.env.REACT_APP_DISCORD,
        target: '_blank',
        rel: 'noreferrer',
      },
      {
        key: 'starters',
        title: t('Global.Starters'),
        icon: <LightningIcon className="h-9 text-white mb-2" />,
        link: space
          ? buildUrlWithSpace('starters')
          : 'https://flotiq.com/starters/',
        target: space ? '' : '_blank',
        rel: space ? '' : 'noreferrer',
      },
    ],
    [t, space, buildUrlWithSpace],
  );

  const message = useMemo(
    () =>
      `${t('Global.Hi')}, ${userData?.firstName || ''} ${
        userData?.lastName || ''
      }`,
    [t, userData?.firstName, userData?.lastName],
  );

  return (
    <div className={twMerge('grid xl:grid-cols-4 w-full grow')}>
      <div
        className="min-h-[calc(100vh-71px)] xl:col-span-3 px-5 md:pl-7 md:pr-3.5 pt-7 pb-0 xl:pb-10"
        {...getTestProps(testId, 'container')}
      >
        <Helmet>
          <title>{t('Global.Dashboard')}</title>
        </Helmet>

        <div className="flex items-center gap-8 gap-md-12">
          {welcomeImage && <ZoomMaleWorkingImage className="w-48" />}
          <div>
            <Heading
              level={3}
              additionalClasses="pb-0 text-xl lg:text-3xl leading-none dark:text-white leading-normal"
              {...getTestProps(testId, 'heading', 'testId')}
            >
              {welcomeImage ? (
                <>
                  <span className="text-blue">{message}</span>
                  <br />
                  {t('Global.WelcomeToFlotiq')}!
                </>
              ) : (
                `${message}!`
              )}
            </Heading>

            {description && (
              <p
                className="mt-2 lg:mt-3 text-base lg:text-lg dark:text-gray-200"
                {...getTestProps(testId, 'description')}
              >
                {description}
              </p>
            )}
          </div>
        </div>

        <Tiles
          items={DEFAULT_TILES}
          layout={'grid'}
          additionalClasses="mt-5 lg:mt-10"
          id="dashboard_cards"
          {...getTestProps(testId, 'tiles', 'testId')}
        />

        {children}
      </div>

      {rightSidebar}
    </div>
  );
};

export default HomeStructure;

HomeStructure.propTypes = {
  /**
   * Test id for home structure
   */
  testId: PropTypes.string,
  description: PropTypes.node,
  rightSidebar: PropTypes.node,
  space: PropTypes.string,
  buildUrlWithSpace: PropTypes.func,
  welcomeImage: PropTypes.bool,
};

HomeStructure.defaultProps = {
  testId: '',
  rightSidebar: '',
  description: '',
  space: '',
  buildUrlWithSpace: (url) => url,
  welcomeImage: false,
};
