import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { Trans, useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import Pagination from '../Pagination/Pagination';
import ResultsPerPageLabel from '../Pagination/ResultsPerPageLabel';
import Tooltip from '../Tooltip/Tooltip';
import LinkButton from '../LinkButton/LinkButton';
import {
  DuplicateIcon,
  RefreshIcon,
  TrashIcon,
  PencilIcon,
} from '../../images/shapes';
import { getTestProps } from '../../lib/helpers';
import useSpace from '../../hooks/useSpace';

const DeleteSelected = ({
  checkedRows,
  handleDeleteClick,
  disabled,
  testId,
}) => {
  const { t } = useTranslation();
  const deleteDisabled = checkedRows.length > 100;
  return (
    <Tooltip
      tooltip={
        deleteDisabled
          ? t('ObjectsOfType.DeletedLimitTooltip')
          : t('ObjectsOfType.DeleteSelected')
      }
      additionalTooltipClasses={'opacity-100 mt-0'}
    >
      <Button
        buttonSize="sm"
        iconImage={<TrashIcon className="w-4 text-red" />}
        onClick={handleDeleteClick}
        buttonColor={'borderless'}
        iconPosition="start"
        additionalChildrenClasses="hidden sm:inline"
        disabled={deleteDisabled || disabled}
        additionalClasses={twMerge(
          'sm:text-xs md:text-sm',
          (deleteDisabled || disabled) &&
            'bg-transaprent hover:bg-transparent active:bg-transparent opacity-50',
        )}
        noPaddings
        {...getTestProps(testId, 'delete-data', 'testId')}
      >
        {t('ObjectsOfType.DeleteCount', { deleteCount: checkedRows.length })}
      </Button>
    </Tooltip>
  );
};

const MassEditSelected = ({
  checkedRows,
  handleMassEditClick,
  disabled,
  testId,
}) => {
  const { t } = useTranslation();
  const editDisabled = checkedRows.length > 100;
  return (
    <Tooltip
      tooltip={
        editDisabled
          ? t('ObjectsOfType.MassEditLimitTooltip')
          : t('ObjectsOfType.MassEditSelected')
      }
      additionalTooltipClasses={'opacity-100 mt-0'}
    >
      <Button
        buttonSize="sm"
        iconImage={<PencilIcon className="w-4 text-blue" />}
        onClick={handleMassEditClick}
        buttonColor={'borderless'}
        iconPosition="start"
        additionalChildrenClasses="hidden sm:inline"
        disabled={disabled || editDisabled}
        additionalClasses={twMerge(
          'sm:text-xs md:text-sm',
          (disabled || editDisabled) > 100 &&
            'bg-transaprent hover:bg-transparent active:bg-transparent opacity-50',
        )}
        noPaddings
        {...getTestProps(testId, 'mass-edit-data', 'testId')}
      >
        {t('ObjectsOfType.MassEditCount', { editCount: checkedRows.length })}
      </Button>
    </Tooltip>
  );
};

const DuplicateSelected = ({
  checkedRows,
  contentTypeName,
  limitReached,
  testId,
}) => {
  const { t } = useTranslation();
  const { buildUrlWithSpace } = useSpace();
  return (
    <Tooltip
      tooltip={limitReached || t('ObjectsOfType.DuplicateSelected')}
      additionalTooltipClasses={'opacity-100 mt-0'}
    >
      <LinkButton
        to={
          contentTypeName === '_webhooks'
            ? buildUrlWithSpace(`webhooks/duplicate/${checkedRows[0]}`)
            : buildUrlWithSpace(
                `content-type-objects/duplicate/${contentTypeName}/${checkedRows[0]}`,
              )
        }
        buttonSize="sm"
        buttonColor={'borderless'}
        iconPosition="start"
        iconImage={<DuplicateIcon className="w-4 text-blue" />}
        additionalChildrenClasses="hidden sm:inline"
        additionalClasses="sm:text-xs md:text-sm"
        noPaddings
        disabled={!!limitReached}
        {...getTestProps(testId, 'duplicate-data', 'testId')}
      >
        {t('Global.Duplicate')}
      </LinkButton>
    </Tooltip>
  );
};

const StatusBar = ({
  rows,
  checkedRows,
  contentTypeName,
  handleDeleteClick,
  handleMassEditClick,
  currentPage,
  pagesCount,
  handlePageChange,
  handleDataUpdate,
  compactForSmall,
  resultsFrom,
  resultsTo,
  resultsTotalCount,
  disabled,
  handleResetGrid,
  limitReached,
  testId,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={twMerge(
        'flex items-center bg-white dark:bg-slate-950 justify-between h-[40px] md:h-[72px] text-indigo-950',
        'dark:text-gray-200 rounded-b-lg border border-gray dark:border-slate-800 border-t-0',
        'text-xs md:text-sm 2xl:text-base',
        compactForSmall ? 'px-2 lg:px-7' : 'px-4 xs:px-7',
        disabled && 'opacity-50 cursor-not-allowed',
      )}
    >
      <div className="inline-flex items-center space-x-1 lg:space-x-4 2xl:space-x-8">
        <span
          className={twMerge(
            compactForSmall
              ? 'hidden md:inline lg:text-sm 2xl:text-base md:w-fit'
              : 'lg:text-sm 2xl:text-base',
          )}
        >
          <Trans i18nKey="ObjectsOfType.Rows">
            Rows:
            <p className="inline font-bold">{{ rows }}</p>
          </Trans>
        </span>
        {checkedRows && (
          <span>
            <Trans i18nKey="ObjectsOfType.Selected">
              Selected:
              <p className="inline font-bold">
                {{ selectedCount: checkedRows.length }}
              </p>
            </Trans>
          </span>
        )}
        {checkedRows?.length > 0 && (
          <DeleteSelected
            checkedRows={checkedRows}
            handleDeleteClick={handleDeleteClick}
            disabled={disabled}
            testId={testId}
          />
        )}
        {checkedRows?.length > 0 && (
          <MassEditSelected
            checkedRows={checkedRows}
            handleMassEditClick={handleMassEditClick}
            disabled={disabled}
            testId={testId}
          />
        )}
        {checkedRows?.length === 1 && (
          <DuplicateSelected
            checkedRows={checkedRows}
            contentTypeName={contentTypeName}
            limitReached={limitReached}
            testId={testId}
          />
        )}
        {handleResetGrid && (
          <Button
            buttonSize="sm"
            iconImage={<RefreshIcon className="text-blue w-4" />}
            onClick={handleResetGrid}
            buttonColor={'borderless'}
            additionalClasses={twMerge('text-sm sm:text-base')}
            noPaddings
            {...getTestProps(testId, 'grid-reset', 'testId')}
          >
            {t('Global.ResetGrid')}
          </Button>
        )}
      </div>
      <div
        className={twMerge(
          'inline-flex items-center space-x-1 lg:space-x-4 2xl:space-x-8',
          disabled && 'pointer-events-none',
        )}
      >
        {!checkedRows?.length && (
          <ResultsPerPageLabel
            resultsIntervalFrom={resultsFrom}
            resultsIntervalTo={resultsTo}
            resultsTotalCount={resultsTotalCount}
            additionalClasses="!p-0 w-0 hidden md:inline md:text-sm md:w-fit"
            {...getTestProps(testId, 'results-per-page-label', 'testId')}
          />
        )}
        {handlePageChange && (
          <Pagination
            page={currentPage}
            numOfPages={pagesCount}
            onPageChange={handlePageChange}
            additionalClasses="!p-0"
            {...getTestProps(testId, 'pagination', 'testId')}
          />
        )}
        <Button
          buttonSize="sm"
          iconImage={<RefreshIcon className="text-blue w-3.5" />}
          onClick={handleDataUpdate}
          buttonColor={'borderless'}
          additionalClasses={twMerge(
            'md:text-sm dark:text-gray-200',
            disabled &&
              'bg-transparent hover:bg-transparent active:bg-transparent',
          )}
          additionalIconClasses="h-4 w-4"
          additionalChildrenClasses="hidden md:inline"
          disabled={disabled}
          noPaddings
          {...getTestProps(testId, 'reload-data', 'testId')}
        >
          {t('ObjectsOfType.ReloadData')}
        </Button>
      </div>
    </div>
  );
};

StatusBar.propTypes = {
  /**
   * Number of rows
   */
  rows: PropTypes.number,
  /**
   * Array of checked rows ids
   */
  checkedRows: PropTypes.array,
  /**
   * Content type name
   */
  contentTypeName: PropTypes.string,
  /**
   * Open modal function
   */
  handleDeleteClick: PropTypes.func,
  /**
   * Open mass edit modal
   */
  handleMassEditClick: PropTypes.func,
  /**
   * Current page number
   */
  currentPage: PropTypes.number,
  /**
   * Number of pages
   */
  pagesCount: PropTypes.number,
  /**
   * Page change function
   */
  handlePageChange: PropTypes.func,
  /**
   * Data update function
   */
  handleDataUpdate: PropTypes.func,
  /**
   * If status bar should be more compact for small devices
   */
  compactForSmall: PropTypes.bool,
  /**
   * Visible results interval from
   */
  resultsFrom: PropTypes.number,
  /*
   * Visible results interval to
   */
  resultsTo: PropTypes.number,
  /*
   * Results total count
   */
  resultsTotalCount: PropTypes.number,
  /*
   * Limit reached info text
   */
  limitReached: PropTypes.string,
  /**
   * Test id for layout
   */
  testId: PropTypes.string,
};

StatusBar.defaultProps = {
  rows: 0,
  compactForSmall: false,
  checkedRows: null,
  handleDeleteClick: /* istanbul ignore next */ () => null,
  handleMassEditClick: /* istanbul ignore next */ () => null,
  currentPage: 1,
  handleDataUpdate: /* istanbul ignore next */ () => null,
  disabled: false,
  limitReached: '',
  testId: '',
};

export default StatusBar;
