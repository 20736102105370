import { Fragment, useMemo, useState, useRef } from 'react';
import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';

// :: Layout
import Layout from '../layout/Layout';
import LayoutSlim from '../layout/LayoutSlim';

// :: hoc
import WrapUserContext from '../hoc/WrapUserContext/WrapUserContext';

// :: Lib helpers
import { handleRedirectUrl, isModuleEnabled } from '../lib/helpers';

// :: Hoc
import NavigatePage from '../hoc/NavigatePage/NavigatePage';

// :: Pages
import AccountActivation from '../pages/AccountActivation/AccountActivation';
import AddContentObject from '../pages/AddContentObject/AddContentObject';
import AddContentTypeDefinition from '../pages/AddContentTypeDefinition/AddContentTypeDefinition';
import APIKeys from '../pages/APIKeys/APIKeys';
import ChangePasswordPage from '../pages/ChangePasswordPage/ChangePasswordPage';
import ChangePasswordRequest from '../pages/ChangePasswordRequest/ChangePasswordRequest';
import ContentTypeDefinitions from '../pages/ContentTypeDefinitions/ContentTypeDefinitions';
import ContentTypeObjects from '../pages/ContentTypeObjects/ContentTypeObjects';
import Home from '../pages/Home/Home';
import Login from '../pages/Login/Login';
import MediaLibrary from '../pages/MediaLibrary/MediaLibrary';
import Page404 from '../pages/Page404/Page404';
import RegisterAppSumo from '../pages/RegisterAppSumo/RegisterAppSumo';
import Register from '../pages/Register/Register';
import Users from '../pages/Users/Users';
import AccountSettings from '../pages/AccountSettings/AccountSettings';
import Starters from '../pages/Starters/Starters';
import AccountDeletion from '../pages/AccountDeletion/AccountDeletion';
import Profile from '../pages/AccountSettings/subpages/Profile';
import AddWebhooks from '../pages/AddWebhooks/AddWebhooks';
import ContentEmpty from '../pages/ContentEmpty/ContentEmpty';
import PluginsLegacy from '../pages/PluginsLegacy/PluginsLegacy';
import UserRoles from '../pages/UserRoles/UserRoles';
import AddUserRole from '../pages/AddUserRole/AddUserRole';
import Logout from '../pages/Logout/Logout';
import Plans from '../pages/Plans/Plans';
import AddPlan from '../pages/AddPlan/AddPlan';
import Plugins from '../pages/Plugins/Plugins';
import EditMedia from '../pages/EditMedia/EditMedia';
import SpacesManage from '../pages/SpacesManage/SpacesManage';
import HomeSlim from '../pages/Home/HomeSlim';
import SpaceModify from '../pages/SpaceModify/SpaceModify';
import Spaces from '../pages/Spaces/Spaces';
import AddSpace from '../pages/AddSpace/AddSpace';
import DirtyHandlerContext from '../contexts/DirtyHandlerContext';
import Blocker from '../components/DirtyHandler/Blocker';

export const ParamKeyPage = ({ paramName, children }) => {
  const params = useParams();
  const key = params[paramName];
  return <Fragment key={key} children={children} />;
};

export const ProtectedRoute = ({ children, redirect }) => {
  const [user] = useLocalStorageState('cms.user');

  const [searchParams] = useSearchParams();
  const redirect_uri = searchParams.get('redirect_uri');
  const response_type = searchParams.get('response_type');
  const redirectUriRef = useRef(null);

  if (redirect_uri && user) {
    clearTimeout(redirectUriRef.current);
    redirectUriRef.current = setTimeout(() => {
      handleRedirectUrl(redirect_uri, response_type, user);
    }, 100);
    return null;
  }

  if (user?.token) {
    return children;
  } else if (redirect) {
    return redirect;
  } else {
    return <Navigate to="/login" replace />;
  }
};

const PageWrapper = ({ children }) => {
  const [dirty, setDirty] = useState(false);

  const dirtyHandlerContextValue = useMemo(
    () => ({ dirty, setDirty }),
    [dirty],
  );

  return (
    <DirtyHandlerContext.Provider value={dirtyHandlerContextValue}>
      {children}
      <Blocker dirty={dirty} />
    </DirtyHandlerContext.Provider>
  );
};

export const webhooksDefinedColumns = () =>
  isModuleEnabled('WEBHOOKS_TYPE')
    ? ['name', 'type', 'url', 'enabled', 'actions']
    : ['name', 'url', 'enabled', 'actions'];

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<NavigatePage />}></Route>

      <Route path="/s/:spaceSlug/" element={<NavigatePage />}>
        <Route
          element={
            <PageWrapper>
              <WrapUserContext>
                <Layout />
              </WrapUserContext>
            </PageWrapper>
          }
        >
          <Route path="" element={<ProtectedRoute children={<Home />} />} />
          <Route
            path="content-type-objects/:contentTypeName"
            element={
              <ProtectedRoute
                children={
                  <ParamKeyPage paramName="contentTypeName">
                    <ContentTypeObjects />
                  </ParamKeyPage>
                }
              />
            }
          />
          <Route
            path="content-type-objects/add/:contentTypeName"
            element={
              <ProtectedRoute children={<AddContentObject mode={'add'} />} />
            }
          />
          <Route
            path="content-type-objects/edit/:contentTypeName/:id"
            element={
              <ProtectedRoute children={<AddContentObject mode={'edit'} />} />
            }
          />
          <Route
            path="content-type-objects/duplicate/:contentTypeName/:id"
            element={
              <ProtectedRoute
                children={<AddContentObject duplicate mode={'duplicate'} />}
              />
            }
          />
          <Route
            path="media"
            element={<ProtectedRoute children={<MediaLibrary />} />}
          />
          <Route
            path="media/edit/:id"
            element={<ProtectedRoute children={<EditMedia />} />}
          />
          <Route
            path="content-type-definitions"
            element={
              <ProtectedRoute
                children={
                  <ContentTypeDefinitions limit={10000} pagination={false} />
                }
              />
            }
          />
          <Route
            path="content-type-definitions/add"
            element={<ProtectedRoute children={<AddContentTypeDefinition />} />}
          />
          <Route
            path="content-type-definitions/edit/:contentTypeName"
            element={<ProtectedRoute children={<AddContentTypeDefinition />} />}
          />
          <Route
            path="content-type-definitions/duplicate/:contentTypeName"
            element={
              <ProtectedRoute
                children={<AddContentTypeDefinition duplicate />}
              />
            }
          />
          <Route
            path="api-keys"
            element={<ProtectedRoute children={<APIKeys />} />}
          />

          <Route
            path="plugins-legacy"
            element={<ProtectedRoute children={<PluginsLegacy />} />}
          />
          <Route
            path="plugins"
            element={<ProtectedRoute children={<Plugins />} />}
          />
          <Route
            path="webhooks"
            element={
              <ProtectedRoute
                children={
                  <ContentTypeObjects
                    allowInternals
                    definedTypeName="_webhooks"
                    definedColumns={webhooksDefinedColumns()}
                  />
                }
              />
            }
          />
          <Route
            path="webhooks/add"
            element={<ProtectedRoute children={<AddWebhooks mode={'add'} />} />}
          />
          <Route
            path="webhooks/edit/:id"
            element={
              <ProtectedRoute children={<AddWebhooks mode={'edit'} />} />
            }
          />
          <Route
            path="webhooks/duplicate/:id"
            element={
              <ProtectedRoute
                children={<AddWebhooks mode={'edit'} duplicate />}
              />
            }
          />
          <Route
            path="user-roles"
            element={<ProtectedRoute children={<UserRoles />} />}
          />
          <Route
            path="user-roles/add"
            element={<ProtectedRoute children={<AddUserRole />} />}
          />
          <Route
            path="user-roles/edit/:id"
            element={<ProtectedRoute children={<AddUserRole />} />}
          />
          <Route
            path="user-roles/duplicate/:id"
            element={<ProtectedRoute children={<AddUserRole duplicate />} />}
          />
          <Route
            path="content"
            element={<ProtectedRoute children={<ContentEmpty />} />}
          />
          <Route
            path="starters"
            element={<ProtectedRoute children={<Starters />} />}
          />
        </Route>
      </Route>

      <Route element={<WrapUserContext />}>
        <Route
          element={
            <PageWrapper>
              <LayoutSlim />
            </PageWrapper>
          }
        >
          <Route
            path="start"
            element={<ProtectedRoute children={<HomeSlim />} />}
          />
        </Route>

        <Route
          element={
            <PageWrapper>
              <LayoutSlim template="settings" />
            </PageWrapper>
          }
        >
          <Route
            path="organization"
            element={<ProtectedRoute children={<SpacesManage />} />}
          />
          <Route
            path="space/add"
            element={<ProtectedRoute children={<SpaceModify mode="add" />} />}
          />
          <Route
            path="space/upgrade/:id"
            element={
              <ProtectedRoute children={<SpaceModify mode="upgrade" />} />
            }
          />
          <Route
            path="space/edit/:id"
            element={<ProtectedRoute children={<SpaceModify mode="edit" />} />}
          />

          <Route
            path="users"
            element={<ProtectedRoute children={<Users />} />}
          />
          <Route
            path="users/add"
            element={
              <ProtectedRoute
                children={<Profile include={true} mode="add" />}
              />
            }
          />
          <Route
            path="users/edit/:id"
            element={
              <ProtectedRoute
                children={<Profile include={true} mode="edit" />}
              />
            }
          />
          <Route
            path="profile"
            element={<ProtectedRoute children={<AccountSettings />} />}
          />
        </Route>
      </Route>

      <Route
        element={
          <WrapUserContext>
            <PageWrapper>
              <Layout />
            </PageWrapper>
          </WrapUserContext>
        }
      >
        <Route
          path="/spaces/edit/:id"
          element={<ProtectedRoute children={<AddSpace />} />}
        />
        <Route
          path="/plans"
          element={<ProtectedRoute children={<Plans />} />}
        />
        <Route
          path="/plans/add/"
          element={<ProtectedRoute children={<AddPlan />} />}
        />
        <Route
          path="/plans/edit/:id"
          element={<ProtectedRoute children={<AddPlan />} />}
        />

        <Route
          path="/users-data-preview"
          element={<ProtectedRoute children={<Users isAllUsers />} />}
        />

        <Route
          path="/spaces-data-preview"
          element={<ProtectedRoute children={<Spaces />} />}
        />
      </Route>

      <Route
        path="/login"
        element={
          <ProtectedRoute
            children={<Navigate to="/" replace />}
            redirect={<Login />}
          />
        }
      />
      <Route path="/logout" element={<Logout />} />
      <Route path="/register" element={<Register />} />
      <Route path="/register/activate" element={<AccountActivation />} />
      <Route path="/delete-account-confirm" element={<AccountDeletion />} />
      <Route path="/change-password" element={<ChangePasswordPage />} />
      <Route
        path="/change-password-request"
        element={<ChangePasswordRequest />}
      />
      <Route path="/register-appsumo" element={<RegisterAppSumo />} />
      <Route path="*" element={<Page404 />} />
    </>,
  ),
);

const AppRouter = () => {
  return <RouterProvider router={router} />;
};

export default AppRouter;
