import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { toast } from 'react-hot-toast';
import useLocalStorageState from 'use-local-storage-state';

// :: Components
import Heading from '../../components/Heading/Heading';
import Loader from '../../components/Loader/Loader';
import StatusBar from '../../components/StatusBar/StatusBar';
import CustomizableDataGrid from '../../components/CustomizableDataGrid/CustomizableDataGrid';
import DataGridControl from '../../components/DataGridControl/DataGridControl';

// :: Utils
import { getLocalStorage, removeLocalStorage } from '../../utils/localStorage';

// :: Hooks
import { useAllUsers, useConstraints, useUsers } from '../../hooks/api';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import useOnce from '../../hooks/useOnce';
import useToken from '../../hooks/useToken';
import useActionsColumn from '../../components/DataGrid/useActions';

// :: Contexts
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';

// :: Lib
import {
  generateExportUsersCsvUrl,
  getTestProps,
  setInitialGridOptions,
  prepareColumns,
} from '../../lib/helpers';
import { getSpacesList } from '../../lib/flotiq-client';
import { checkResponseStatus } from '../../lib/flotiq-client/response-errors';

// :: Inner
import { getDefinedColumns } from './definedColumns';

// :: Images
import { AvatarIcon, HouseIcon, WarningIcon } from '../../images/shapes';
import { actionsRenderer } from '../../components/DataGrid/DataGridCell/cellRenderer';

const getApiFilters = (filters) => {
  if (!filters) return {};

  const newFilters = {};

  Object.keys(filters).forEach((key) => {
    if (key === 'organization.name') {
      newFilters.organizationName = filters['organization.name'].value;
    } else if (key === 'organization.id') {
      newFilters.organizationId = filters['organization.id'].value;
    } else if (key === 'roles') {
      newFilters.roles = filters[key].value.join(',');
    } else {
      newFilters[key] = filters[key].value;
    }
  });

  return newFilters;
};

const Users = ({ isAllUsers, testId }) => {
  const jwt = useToken();
  const [, setImpersonate] = useLocalStorageState('cms.impersonate');
  const SORT_KEY = isAllUsers ? 'cms.all-users-sort' : 'cms.user-sort';
  const OPTIONS_KEY = isAllUsers
    ? 'cms.all-users-grid-state'
    : 'cms.user-grid-state';

  const usersGridContainer = useRef();
  const { t } = useTranslation();
  const { updateAppContext } = useContext(AppContext);
  const { isRoleAdmin, isAdmin, userStorage: user } = useContext(UserContext);

  const usersLimit = useMemo(
    () => user?.data?.organizationLimits?.userLimit,
    [user],
  );
  const [gridOptions, setGridOptions] = useState(
    getLocalStorage(OPTIONS_KEY, true),
  );

  const { entity: membersCount } = useConstraints('organization-users');

  const disabledAdding = useMemo(
    () =>
      isAllUsers || usersLimit === -1
        ? false
        : membersCount?.data >= usersLimit,
    [isAllUsers, usersLimit, membersCount?.data],
  );

  const [sort, setSort] = useState(getLocalStorage(SORT_KEY, true));
  const [firstLoading, setFirstLoading] = useState(true);
  const [initDataHasContent, setInitDataHasContent] = useState(false);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [columns, setColumns] = useState([]);
  const [editGrid, setEditGrid] = useState(false);
  const [filterGrid, setFilterGrid] = useState(false);

  useEffect(() => {
    setSort(getLocalStorage(SORT_KEY, true));
  }, [SORT_KEY]);

  useEffect(() => {
    setGridOptions(getLocalStorage(OPTIONS_KEY, true));
  }, [OPTIONS_KEY]);

  const topBarButtons = useMemo(() => {
    const buttons = [];

    if (isAdmin || isRoleAdmin) {
      buttons.push({
        label: t('Users.Add'),
        link: '/users/add',
        disabled: disabledAdding,
        tooltip: disabledAdding ? t('Users.LimitReached') : '',
        tooltipPlacement: 'leftBottom',
        additionalTooltipClasses: 'hidden md:block',
        key: 'Add',
      });
    }

    if (isRoleAdmin && isAllUsers) {
      buttons.push({
        label: t('Users.Export'),
        link: generateExportUsersCsvUrl(jwt),
        key: 'Export',
        ...getTestProps(testId, `export-link`, 'testId'),
        menuItems: [
          {
            key: 'paying',
            label: t('Users.ExportPaying'),
            link: generateExportUsersCsvUrl(jwt, { paying: true }),
          },
          {
            key: 'freeLogged',
            label: t('Users.ExportFreeLogged'),
            link: generateExportUsersCsvUrl(jwt, {
              planName: 'free',
              loginFromDays: 0,
              loginToDays: 30,
            }),
          },
          {
            key: '90days',
            label: t('Users.Export90Days'),
            link: generateExportUsersCsvUrl(jwt, {
              loginFromDays: 90,
              loginToDays: 179,
            }),
          },
          {
            key: '180days',
            label: t('Users.Export180Days'),
            link: generateExportUsersCsvUrl(jwt, {
              loginFromDays: 180,
              loginToDays: 364,
            }),
          },
          {
            key: '365days',
            label: t('Users.Export365Days'),
            link: generateExportUsersCsvUrl(jwt, {
              loginFromDays: 365,
            }),
          },
        ],
      });
    }

    return buttons;
  }, [disabledAdding, isAllUsers, isRoleAdmin, isAdmin, jwt, t, testId]);

  const handlePageUpdate = useCallback(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      page: isAllUsers ? 'admin/all-users' : 'users',
      menuItemOpen: isAllUsers ? 'admin' : '',
      topBar: {
        heading: isAllUsers ? t('Global.AllUsers') : t('Global.Users'),
        buttons: topBarButtons,
      },
      id: isAllUsers ? 'allUsers' : 'users',
    }));
  }, [updateAppContext, isAllUsers, t, topBarButtons]);

  useOnce(handlePageUpdate);

  const usersParams = useMemo(
    () => ({
      page,
      limit,
      ...getApiFilters(filters),
      ...(sort?.sortOrder ? { order_direction: sort?.sortOrder } : {}),
      ...(sort?.sortField ? { order_by: sort?.sortField } : {}),
    }),
    [page, limit, filters, sort?.sortOrder, sort?.sortField],
  );

  const usersOptions = useMemo(
    () => ({
      pause: isAllUsers,
    }),
    [isAllUsers],
  );

  const {
    data: users,
    errors: usersError,
    status: usersStatus,
    isLoading: usersAreLoading,
    pagination: usersPagination,
    reload: usersReload,
  } = useUsers(usersParams, usersOptions);

  const allUsersOptions = useMemo(
    () => ({
      pause: !isRoleAdmin || !isAllUsers,
    }),
    [isRoleAdmin, isAllUsers],
  );

  const {
    data: allUsers,
    errors: allUsersError,
    status: allUsersStatus,
    isLoading: allUsersAreLoading,
    pagination: allUsersPagination,
    reload: allUsersReload,
  } = useAllUsers(usersParams, allUsersOptions);

  useApiErrorsToast(usersError);
  useApiErrorsToast(allUsersError);

  const pagination = useMemo(
    () => (isAllUsers ? allUsersPagination : usersPagination),
    [allUsersPagination, isAllUsers, usersPagination],
  );

  useEffect(() => {
    if (firstLoading) {
      setInitDataHasContent(false);
    }
    if (
      (!usersAreLoading && !isAllUsers) ||
      (!allUsersAreLoading && isAllUsers)
    )
      setFirstLoading(false);
  }, [allUsersAreLoading, firstLoading, isAllUsers, usersAreLoading]);

  useEffect(() => {
    if (!initDataHasContent && (users.length || allUsers.length)) {
      setInitDataHasContent(true);
    }
  }, [users.length, initDataHasContent, allUsers.length]);

  const handleResetGrid = useCallback(() => {
    setSort();
    setPage(1);
    setLimit(20);
    setFilters({});
    removeLocalStorage(SORT_KEY);
    removeLocalStorage(OPTIONS_KEY);
    setGridOptions();
  }, [OPTIONS_KEY, SORT_KEY]);

  const handleLimitChange = useCallback((resultsLimit) => {
    setLimit(resultsLimit);
    setPage(1);
  }, []);

  const handlePageChange = useCallback((page) => {
    setPage(page);
  }, []);

  const filtersApplied = useMemo(
    () =>
      filters
        ? Object.keys(filters).filter((key) => filters[key])?.length > 0
        : false,
    [filters],
  );

  const handleFilters = useCallback((currentFilters) => {
    setFilters(currentFilters);
    setPage(1);
  }, []);

  useEffect(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      breadcrumbs: [
        {
          label: <HouseIcon className="w-3 text-blue" />,
          link: '/',
          additionalClasses: 'text-slate-400 truncate text-center',
          key: 'Dashboard',
        },
        {
          label: isAllUsers ? t('Global.AllUsers') : t('Global.Users'),
          additionalClasses: 'text-zinc-600 truncate',
          disabled: true,
          key: 'Users',
        },
      ],
    }));
  }, [isAllUsers, t, updateAppContext]);

  const showGrid = useMemo(() => {
    if (!isAllUsers)
      return (
        users.length > 0 ||
        (usersAreLoading && !firstLoading) ||
        filtersApplied ||
        initDataHasContent
      );
    return (
      isRoleAdmin &&
      (allUsers.length > 0 ||
        (allUsersAreLoading && !firstLoading) ||
        filtersApplied ||
        initDataHasContent)
    );
  }, [
    isAllUsers,
    users.length,
    usersAreLoading,
    firstLoading,
    filtersApplied,
    initDataHasContent,
    isRoleAdmin,
    allUsers.length,
    allUsersAreLoading,
  ]);

  const emptyData = useMemo(() => {
    if (showGrid) return null;
    if (firstLoading && (!isAllUsers || isRoleAdmin)) {
      return (
        <Loader
          size="big"
          type="spinner-grid"
          {...getTestProps(testId, 'loading', 'testId')}
        />
      );
    }
    return (
      <Heading
        level={2}
        additionalClasses="text-3xl md:text-4xl leading-8 dark:text-white"
      >
        <div
          className="flex flex-col items-center justify-center text-center"
          {...getTestProps(testId, 'empty-data')}
        >
          <WarningIcon className="text-red w-14 md:w-20 mb-3" />
          {(usersStatus || allUsersStatus) === 403 ||
          (!isRoleAdmin && isAllUsers)
            ? t('Global.NoAccess')
            : t('Media.OnErrorMessage')}
        </div>
      </Heading>
    );
  }, [
    showGrid,
    firstLoading,
    isAllUsers,
    isRoleAdmin,
    testId,
    usersStatus,
    allUsersStatus,
    t,
  ]);

  const handleInitialGridOptions = useCallback(
    (cols, minWidth) => {
      setInitialGridOptions(
        cols,
        minWidth,
        usersGridContainer.current.offsetWidth - 50,
        setGridOptions,
        setColumns,
        OPTIONS_KEY,
      );
    },
    [OPTIONS_KEY],
  );

  const handleFilterDataSourceFetch = useCallback(
    async (accessor, query) => {
      if (accessor === 'spaces') {
        try {
          const { body: Objects, status: ObjectsStatus } = await getSpacesList(
            jwt,
            undefined,
            {
              name: query ? query : '',
              page: 1,
              limit: 10,
              order_by: 'name',
              order_direction: 'asc',
            },
          );

          checkResponseStatus(Objects, ObjectsStatus);

          return Objects.data.map((el) => ({
            value: el.name,
            label: el.name,
          }));
        } catch (error) {
          if (error.message) {
            toast.error(error.message);
          } else {
            toast.error(t('Spaces.SpacesListFetchError'));
          }
        }
      }

      return [];
    },
    [t, jwt],
  );

  useEffect(() => {
    prepareColumns(
      gridOptions,
      OPTIONS_KEY,
      editGrid,
      t,
      testId,
      setGridOptions,
      handleInitialGridOptions,
      setColumns,
      getDefinedColumns,
      isAllUsers,
      handleFilterDataSourceFetch,
      isRoleAdmin,
    );
  }, [
    OPTIONS_KEY,
    editGrid,
    gridOptions,
    handleFilterDataSourceFetch,
    handleInitialGridOptions,
    isAllUsers,
    isRoleAdmin,
    t,
    testId,
  ]);

  const impersonateAction = useCallback(
    ({ email, id }) => ({
      key: 'impersonate',
      label: t('Global.Impersonate'),
      onClick: () => {
        setImpersonate({
          email,
          id,
        });
        updateAppContext?.((prevState) => ({
          ...prevState,
          space: undefined,
          spaceSlug: undefined,
        }));
      },
      icon: <AvatarIcon className="text-gray-500 w-4 min-w-4" />,
      link: '/',
    }),
    [setImpersonate, t, updateAppContext],
  );

  const impersonateColumn = useMemo(
    () => ({
      accessor: '_impersonate',
      label: '',
      width: 50,
      minWidth: 50,
      flexGrow: 0,
      justify: 'center',
      notDraggable: true,
      render: (_, rowData) =>
        actionsRenderer(rowData, [impersonateAction(rowData)], []),
      resizable: false,
      sortable: false,
    }),
    [impersonateAction],
  );

  const actionColumn = useActionsColumn('/users');

  const allColumns = useMemo(
    () => [
      actionColumn,
      ...(isAllUsers ? [impersonateColumn] : []),
      ...columns,
    ],
    [actionColumn, isAllUsers, impersonateColumn, columns],
  );

  return (
    <div className="w-full md:h-[calc(100vh-66px)]">
      <Helmet>
        <title>{t('Global.Users')}</title>
      </Helmet>
      <div className="flex flex-col h-full w-full">
        <div
          className={twMerge(
            'flex flex-col xs:flex-row items-center justify-between relative w-full px-5 lg:px-7',
            'space-y-3 xs:space-y-0',
            isAllUsers
              ? 'bg-slate-50 mb-7 py-3 xs:py-2 border-b dark:bg-gray-900 dark:border-b dark:border-slate-800'
              : 'bg-transparent py-3',
          )}
        >
          <DataGridControl
            displayGridCheckboxes={showGrid && columns.length > 0}
            displayGridControl={showGrid}
            editGrid={editGrid}
            filterGrid={filterGrid}
            setEditGrid={setEditGrid}
            setFilterGrid={setFilterGrid}
            handleResetGrid={handleResetGrid}
            limit={limit}
            handleLimitChange={handleLimitChange}
            columns={columns}
            gridOptions={gridOptions}
            optionsLocalStorageKey={OPTIONS_KEY}
            setGridOptions={setGridOptions}
            columnsVisibilityAdditionalClasses={twMerge(
              isAllUsers && '-top-2 xs:top-[0.2rem]',
            )}
            testId={testId}
          />
        </div>
        <div className="h-full w-full px-5 lg:px-7 rounded-lg">
          <div
            className="w-full h-[calc(100vh-230px)] md:h-[calc(100%-150px)]"
            ref={usersGridContainer}
          >
            {showGrid ? (
              <CustomizableDataGrid
                columns={allColumns}
                setColumns={setColumns}
                data={isAllUsers ? allUsers : users}
                isLoading={isAllUsers ? allUsersAreLoading : usersAreLoading}
                setSort={setSort}
                sort={sort?.sortField || undefined}
                sortOrder={sort?.sortOrder || undefined}
                sortingLocalStorageKey={SORT_KEY}
                optionsLocalStorageKey={OPTIONS_KEY}
                setCurrentPage={setPage}
                editGrid={editGrid}
                gridOptions={gridOptions}
                setGridOptions={setGridOptions}
                statusBar={
                  <StatusBar
                    rows={pagination.count}
                    currentPage={page}
                    pagesCount={pagination.total_pages}
                    handlePageChange={handlePageChange}
                    handleDataUpdate={isAllUsers ? allUsersReload : usersReload}
                    resultsFrom={(pagination.current_page - 1) * limit + 1}
                    resultsTo={
                      (pagination.current_page - 1) * limit + pagination.count
                    }
                    resultsTotalCount={pagination.total_count}
                    {...getTestProps(testId, 'statusbar', 'testId')}
                  />
                }
                noDataInfoText={t('Users.FiltersEmptyResult')}
                hasFilters={filterGrid}
                filters={filters}
                disableFilters={editGrid}
                onFilter={handleFilters}
                additionalClasses="bg-white dark:bg-gray-900"
                {...getTestProps(testId, 'grid', 'testId')}
              />
            ) : (
              <div className="flex flex-col items-center justify-center h-full">
                {emptyData}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;

Users.propTypes = {
  /**
   * If page is for all users data
   */
  isAllUsers: PropTypes.bool,
  /**
   * Test id for users page
   */
  testId: PropTypes.string,
};

Users.defaultProps = {
  isAllUsers: false,
  testId: 'users-grid',
};
